import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueGtag from "vue-gtag";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

import ApolloClient from "apollo-boost";
import VueApollo from "vue-apollo";
const apolloClient = new ApolloClient({
  uri: "/graphql"
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

Vue.use(VueGtag, {
  config: { id: "G-N7K7BNFTC9" }
});

Vue.use(VueApollo);

Vue.use(VueApollo);

new Vue({
  router,
  store,
  provide: apolloProvider.provide(),
  render: h => h(App)
}).$mount("#app");
